<template>
  <m-basic-form
    :showButtons="false"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
  >
    <div class="panel">
      <div class="panel-top">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-10">
                <m-select
                  v-model="form.VEHICLEID"
                  :options="options.vehicles"
                  langlabel="vehicleselect"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <m-input
                  v-model="barcodeStr"
                  @keyup.enter.prevent="searchBarcode"
                  langlabel="barcode"
                  :readonly="loadingBtn"
                  :disabled="veihcleIsSelected"
                />
              </div>
              <div class="col-2">
                <b-btn
                  squared
                  size="sm"
                  :disabled="loadingBtn || veihcleIsSelected"
                  variant="primary"
                  @click="searchBarcode"
                >
                  <b-spinner small v-show="loadingBtn"></b-spinner>
                  <span v-show="!loadingBtn"> {{ $t("btn.addbarcode") }} </span>
                </b-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <m-yes-no
                  label="Uyarıları gösterme"
                  v-model="checkWarningStatus"
                />
              </div>
            </div>
          </div>
          <div class="col-6" style="max-height: 150px; overflow: auto">
            <span
              style="display: block"
              v-for="(error, index) in errors"
              :key="index"
              >{{ error }}</span
            >
          </div>
        </div>
      </div>
      <div class="panel-bottom">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark"
          :components="components"
          :columnDefs="columnDefs"
          :rowData="rowData"
          rowSelection="multiple"
          :enableColResize="true"
          @rowDoubleClicked="(row) => this.$emit('rowDoubleClicked', row)"
          :gridOptions="gridOptions"
          ref="myGrid"
          :localeText="{ noRowsToShow: $t('grid.nodata') }"
        ></ag-grid-vue>
      </div>
    </div>

    <template slot="footer">
      <b-button
        @click="save"
        size="sm"
        squared
        variant="success"
        :disabled="this.isSaved"
        >{{ $t("buton.vehicleupload") }}</b-button
      >
    </template>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";

import { AgGridVue } from "ag-grid-vue";
import "../../libs/GridValueFormatter";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import config from "../../config";
export default {
  mounted() {
    api.getVehicle().then((res) => {
      if (res.data.status) {
        this.options.vehicles = res.data.data.rows.map((item) => {
          return {
            text: item.VEHICLEBARCODE,
            value: item.ID,
          };
        });
      }
    });
  },
  components: {
    AgGridVue,
  },
  computed: {
    veihcleIsSelected() {
      return !this.form.VEHICLEID;
    },
  },
  data() {
    return {
      errors: [],
      checkWarningStatus: "0",

      isSaved: false,
      loadingBtn: false,
      barcodeStr: null,

      ID: null,
      form: {
        VEHICLEID: null,

        barcodes: [],
      },
      loadingShow: false,
      loadingMsg: null,

      options: {
        vehicles: [],
      },

      columnDefs: null,
      rowData: [],
      components: null,
      gridOptions: null,
    };
  },
  beforeMount() {
    this.gridOptions = {};
    api.getCols("cargovehicleaction").then((res) => {
      this.columnDefs = res.data.map((item) => {
        if ("valueFormatter" in item) {
          switch (item.valueFormatter) {
            case "yesNo":
              item.valueFormatter = yesNo;
              break;
          }
        }
        //dil dönüşümü
        item.headerName = this.$t("list." + item.field);
        return item;
      });
    });
  },
  methods: {
    swal(title, error, type, barcode = "") {
      if (this.checkWarningStatus == "0") {
        return swal(title, error, type);
      }

      this.errors.push(barcode + ": " + error);
      return Promise.resolve(true);
    },
    searchBarcode() {
      if (this.barcodeStr == null || this.barcodeStr == "") {
        this.swal(
          this.$t("dialog.notice"),
          "Barkod boş olamaz",
          "error",
          this.barcodeStr
        );
        return;
      }

      this.loadingBtn = true;

      let barcode = null;
      let findedDuplicateBarcode = false;

      if (config.USE_MULTI_READ_BARCODE) {
        barcode = this.barcodeStr;

        findedDuplicateBarcode = this.form.barcodes.find((item) => {
          return item.BARCODE == barcode;
        });
      } else {
        const barcodes = this.barcodeStr.split(" ");
        barcode = barcodes[0];

        findedDuplicateBarcode = this.rowData.find((item) => {
          return item.BARCODE == barcode;
        });
      }

      if (findedDuplicateBarcode) {
        this.swal(
          this.$t("dialog.notice"),
          "Bu barkodu daha önce eklediniz!",
          "error",
          barcode
        );
        this.loadingBtn = false;
        this.barcodeStr = null;

        return;
      }

      api
        .searchBarcode("upload", barcode, this.form.VEHICLEID)
        .then((res) => {
          if (res.data.status) {
            const data = res.data.data;
            if (data.ISCANCEL == "1") {
              this.swal(
                this.$t("dialog.notice"),
                "İptal edilmiş kargo üzerinde işlem yapamazsınız!",
                "error",
                barcode
              );
              this.loadingBtn = false;
              this.barcodeStr = null;

              return;
            }

            if (data.ISDELIVERY == "1") {
              this.swal(
                this.$t("dialog.notice"),
                "Teslim edilmiş kargo üzerinde işlem yapamazsınız!",
                "error",
                barcode
              );
              this.loadingBtn = false;
              this.barcodeStr = null;

              return;
            }

            api
              .searchCargoForVehicleAction(data.CARGOID, data.CARGODETAILID)
              .then((res) => {
                if (res.data.status) {
                  this.rowData.push(res.data.data[0]);
                  this.form.barcodes.push(data);
                } else {
                  this.swal(
                    this.$t("dialog.notice"),
                    res.data.errMsg,
                    "error",
                    barcode
                  );
                }
              })
              .catch((err) => {
                this.swal(
                  this.$t("dialog.systemerror"),
                  this.$t("dialog.anerrorhasccurred") + err.toString(),
                  "error",
                  barcode
                );
              })
              .finally(() => {
                this.loadingBtn = false;
                this.barcodeStr = null;
              });
          } else {
            this.swal(
              this.$t("dialog.notice"),
              res.data.errMsg,
              "error",
              barcode
            );
            this.loadingBtn = false;
            this.barcodeStr = null;

            return;
          }
        })
        .catch((err) => {
          this.swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error",
            barcode
          );
        })
        .finally(() => {
          this.loadingBtn = false;
          this.barcodeStr = null;
        });

      return;
    },
    save() {
      if (this.form.barcodes.length) {
        this.loadingMsg = this.$t("dialog.savingrecord");
        this.loadingShow = true;

        api
          .saveCargoUpDown(
            "upload",
            this.form.barcodes.map((item) => {
              if (item.TYPE == "manifest") {
                return {
                  TYPE: item.TYPE,
                  MANIFESTID: item.ID,
                  BARCODE: item.BARCODE,
                  VEHICLEID: this.form.VEHICLEID,
                };
              } else {
                return {
                  TYPE: item.TYPE,
                  CARGOID: item.CARGOID,
                  CARGODETAILID: item.CARGODETAILID,
                  VEHICLEID: this.form.VEHICLEID,
                };
              }
            })
          )
          .then((res) => {
            if (res.data.status) {
              swal("Başarılı", "Başarıyla kargolar yüklendi!", "success");
              this.isSaved = true;
            } else {
              swal("Uyarı", res.data.errMsg, "error");
            }
          })
          .finally(() => {
            this.loadingShow = false;
          });
      } else {
        swal("Uyarı", "En az 1 barkod okutmanız gerekmektedir!", "error");
      }
    },
  },
};
</script>

<style lang="scss">
.panel {
  width: 100%;
  height: 100%;

  .panel-top {
    width: 100%;
    height: 150px;
  }

  .panel-bottom {
    width: 100%;
    height: calc(100% - 150px);
  }
}
</style>